/* eslint-disable @typescript-eslint/no-unused-vars */

/** Global Type Imports **/

import {
  AtrigamAreaId, AtrigamAreaName, AtrigamBatchOperationInviteUsers, AtrigamBatchOperationPrepareStaging, AtrigamBatchOperationsQueueItem, AtrigamBatchOperationUploadData, AtrigamBatchOperationUploadDataValidationReport, AtrigamBatchOperationWipeData, AtrigamDatabaseUniverseEnvironment, AtrigamEnvironment, AtrigamNotificationPushStateType, AtrigamObjectName, AtrigamObjectSubscription,
  AtrigamPendingInvitation,
  AtrigamPendingInvitee, AtrigamPhoneNumber, AtrigamPlatformApps, AtrigamPushNotificationData,
  AtrigamPushToken, AtrigamRole, AtrigamSubscriptionRoleInvite, AtrigamSubscriptionState, AtrigamTaskFlowId, AtrigamTaskFlowName, AtrigamUniverseId, AtrigamUniverseName, AtrigamUniverseNodeName, AtrigamUniverseRegistration, AtrigamUser, AtrigamUserInfo, AtrigamUserProfile, AtrigamWorkItemId, Brand, Email, FirestoreTimestamp, UID, URI
} from '@atrigam/atrigam-types';


// organize-imports-disable
import { createHttpFunctionClientSDK } from './helpers/createHttpFunction.clientSDK';
import { createOnCallFunctionClientSDK } from './helpers/createOnCallFunction.clientSDK';
// organize-imports-enable

export const APP_NAME = 'atrigam-eu';
export const APP_URL_TEMPLATE = 'https://%REGION%-atrigam-eu.cloudfunctions.net/%FUNCTION%';
export const FUNCTION_VERSION = '3.0.1';

/** Local Imports **/

export interface AtrigamUniverseBackupConfiguration {
    active: boolean;
    environment: AtrigamEnvironment;
    id: AtrigamDatabaseUniverseEnvironment;
    lastBackup?: FirestoreTimestamp;
    lastBackupNode?: AtrigamBackupFirestoreNode;
    lastBackupTimestamp?: FirestoreTimestamp;
    lastRun?: AtrigamUniverseBackupConfigurationLastRun;
    lastRunError?: AtrigamUniverseBackupConfigurationLastRunError;
    numberOfSuccessfulBackups?: number;
    objects: AtrigamObjectName[];
    schedule: AtrigamUniverseBackupSchedule;
    universe: AtrigamUniverseNodeName;
}

// f.e. 2022-11-03-07-50-25
export type AtrigamBackupFirestoreNode = Brand<string, "AtrigamBackupFirestoreNode">;

export interface AtrigamUniverseBackupSchedule {
    hours?: number;
    minutes?: number;
    seconds?: number;
}

export interface AtrigamUniverseBackupConfigurationLastRun {
    backupExportEndTime: FirestoreTimestamp;
    backupExportStartTime: FirestoreTimestamp;
    backupStoreEndTime: FirestoreTimestamp;
    backupStoreStartTime: FirestoreTimestamp;
    durationExport: AtrigamBackupFirestoreDuration;
    durationStore: AtrigamBackupFirestoreDuration;
}

export interface AtrigamUniverseBackupConfigurationLastRunError {
    error: string;
    backupExportStartTime: FirestoreTimestamp;
}

export interface AtrigamBackupFirestoreDuration {
    hours: number;
    milliseconds: number;
    minutes: number;
    seconds: number;
}

/** Function Definitions **/

// user-handOverTaskFlow 
export interface handOverTaskFlowParameters {
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
    targetUid: UID;
}
export interface handOverTaskFlowResult {
    success: true;
}
export const handOverTaskFlowHttpOnCallFunction = createOnCallFunctionClientSDK<handOverTaskFlowParameters, handOverTaskFlowResult, unknown>({ appName: APP_NAME, functionName: 'user-handOverTaskFlow', region: 'europe-west3' });

// user-getUsersInfo 
export interface getUsersInfoParameters {
    uids: UID[];
}
export type getUsersInfoResult = AtrigamUserInfo[];
export const getUsersInfoHttpOnCallFunction = createOnCallFunctionClientSDK<getUsersInfoParameters, getUsersInfoResult, unknown>({ appName: APP_NAME, functionName: 'user-getUsersInfo', region: 'europe-west3' });

// user-getUserInfo 
export interface getUserInfoParameters {
    email?: Email;
    phoneNumber?: AtrigamPhoneNumber;
    uid?: UID;
}
export type getUserInfoResult = AtrigamUserInfo;
export const getUserInfoHttpFunction = createHttpFunctionClientSDK<getUserInfoParameters, getUserInfoResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'user-getUserInfo', region: 'europe-west3'  });

// user-verifyEmail 
export interface verifyEmailParameters {
    token: string;
}
export type verifyEmailResult = boolean;
export interface verifyEmailError {
    code: "functions/deadline-exceeded" | "functions/invalid-argument" | "functions/internal"
    // additional
    // | unknown
     | undefined;
    message: string;
}
export const verifyEmailHttpOnCallFunction = createOnCallFunctionClientSDK<verifyEmailParameters, verifyEmailResult, verifyEmailError>({ appName: APP_NAME, functionName: 'user-verifyEmail', region: 'europe-west3' });

// user-sendVerificationLink 
export type sendVerificationLinkParameters = unknown;
export type sendVerificationLinkResult = boolean;
export const sendVerificationLinkHttpOnCallFunction = createOnCallFunctionClientSDK<sendVerificationLinkParameters, sendVerificationLinkResult, unknown>({ appName: APP_NAME, functionName: 'user-sendVerificationLink', region: 'europe-west3' });

// user-signupUser 
export interface signupUserParameters {
    email: Email;
    password: string;
    acceptTerms: boolean;
}
export interface signupUserResult {
    user: AtrigamUser;
    userProfile: AtrigamUserProfile;
}
export interface signupUserError {
    code: "auth/email-already-in-use" | "auth/invalid-email" | "auth/weak-password"
    // our own
     | "functions/invalid-argument" | "functions/already-exists"
    // additional
    // | unknown
     | undefined;
    message: string;
}
export const signupUserHttpOnCallFunction = createOnCallFunctionClientSDK<signupUserParameters, signupUserResult, signupUserError>({ appName: APP_NAME, functionName: 'user-signupUser', region: 'europe-west3' });

// user-sendPushNotification 
export interface sendPushNotificationParameters {
    uid?: UID;
    email?: Email;
    title?: string;
    message?: string;
    data: AtrigamPushNotificationData;
    forcePush?: boolean;
}
export type sendPushNotificationResult = ErrorResult | {
    expo: ExpoResult;
    fcm: FcmResult;
} | SpoolResult;
export interface ErrorResult {
    error: string;
}
export interface ExpoResult {
    data: ExpoData;
    status: AtrigamNotificationPushStateType;
}
export type ExpoData = {
    info: string;
    expoData: {
        status: "ok";
        id: UID;
    };
} | {
    error: string;
    expoData: {
        status: "error";
        message: string;
        details: Record<string, string>;
    } | null;
} | undefined;
export interface FcmResult {
    data: FcmData;
    status: AtrigamNotificationPushStateType;
}
export interface SpoolResult {
    isSpooled: boolean;
    notificationId: string;
}
export type FcmData = {
    error?: string;
    successes: WrappedResponse[];
    failures: WrappedResponse[];
    removedTokens: AtrigamPushToken[];
    fcmTokens: AtrigamPushToken[];
} | {
    error: string;
    data?: Record<string, string>;
    fcmTokens: AtrigamPushToken[];
} | undefined;
export interface WrappedResponse {
    token: AtrigamPushToken;
    response: unknown;
}
export const sendPushNotificationHttpOnCallFunction = createOnCallFunctionClientSDK<sendPushNotificationParameters, sendPushNotificationResult, unknown>({ appName: APP_NAME, functionName: 'user-sendPushNotification', region: 'europe-west3' });

// user-inviteUser 
export interface inviteUserParameters extends Pick<AtrigamObjectSubscription, "area" | "environment" | "node" | "objectName" | "universe"> {
    subscriptionState?: AtrigamSubscriptionState;
    flow: AtrigamTaskFlowName;
    role: AtrigamRole;
    email?: string;
    name?: string;
    phoneNumber?: string;
    uid?: UID;
    // flags
    addFavorite?: boolean;
    allowCreatePending?: boolean;
    sendPushNotification?: boolean;
}
export type inviteUserResult = ResponseAtrigamUser | PendingInviteResult;
interface ResponseAtrigamUser {
    user: AtrigamUserInfo;
}
interface PendingInviteResult {
    invitee: AtrigamPendingInvitee;
    invite: AtrigamPendingInvitation;
}
export const inviteUserHttpOnCallFunction = createOnCallFunctionClientSDK<inviteUserParameters, inviteUserResult, unknown>({ appName: APP_NAME, functionName: 'user-inviteUser', region: 'europe-west3' });

// user-unImpersonateUser 
export type unImpersonateUserParameters = unknown;
export type unImpersonateUserResult = boolean;
export const unImpersonateUserHttpOnCallFunction = createOnCallFunctionClientSDK<unImpersonateUserParameters, unImpersonateUserResult, unknown>({ appName: APP_NAME, functionName: 'user-unImpersonateUser', region: 'europe-west3' });

// user-impersonateUser 
export interface impersonateUserParameters {
    uid: UID;
}
export type impersonateUserResult = boolean;
export const impersonateUserHttpOnCallFunction = createOnCallFunctionClientSDK<impersonateUserParameters, impersonateUserResult, unknown>({ appName: APP_NAME, functionName: 'user-impersonateUser', region: 'europe-west3' });

// universes-getWorkItemsInfoForEnvironment 
export interface getWorkItemsInfoForEnvironmentParameters {
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
    universe: AtrigamUniverseName;
    environment: AtrigamEnvironment;
}
export interface getWorkItemsInfoForEnvironmentResult {
    workItemCount: number;
    rolesInFlow: AtrigamRole[];
    modelAvailable: boolean;
    subscriptionsCount?: number;
}
export const getWorkItemsInfoForEnvironmentHttpOnCallFunction = createOnCallFunctionClientSDK<getWorkItemsInfoForEnvironmentParameters, getWorkItemsInfoForEnvironmentResult, unknown>({ appName: APP_NAME, functionName: 'universes-getWorkItemsInfoForEnvironment', region: 'europe-west3' });

// universes-createOrUpdateWorkItem 
interface createOrUpdateWorkItemParameters {
    data: {
        areaId: AtrigamAreaId;
        authToken: string;
        environment: AtrigamEnvironment;
        data: Record<string, unknown>;
        taskFlowId: AtrigamTaskFlowId;
        universeId: AtrigamUniverseId;
    };
}
export type createOrUpdateWorkItemResult = CreateOrUpdateWorkItemHttpFunctionSuccessResult | CreateOrUpdateWorkItemHttpFunctionErrorResult;
export interface CreateOrUpdateWorkItemHttpFunctionSuccessResult {
    type: "Success";
    message: string;
    workItemId: AtrigamWorkItemId;
    url: URI;
}
export interface CreateOrUpdateWorkItemHttpFunctionErrorResult {
    type: "Error";
    code: "failed-precondition" | "internal" | "invalid-argument" | "not-found" | "out-of-range" | "permission-denied" | "unauthenticated" | "unimplemented";
    message: string;
    invalidFields?: CreateOrUpdateWorkItemHttpFunctionInvalidFieldError[];
}
export interface CreateOrUpdateWorkItemHttpFunctionInvalidFieldError {
    sourceName: string;
    targetName: string;
    message: string;
}
export const createOrUpdateWorkItemHttpFunction = createHttpFunctionClientSDK<createOrUpdateWorkItemParameters, createOrUpdateWorkItemResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'universes-createOrUpdateWorkItem', region: 'europe-west3'  });

// universes-getWorkItemSubscribers 
export interface getWorkItemSubscribersParameters {
    environment: AtrigamEnvironment;
    node: AtrigamWorkItemId;
    objectName: AtrigamObjectName;
    universeNodeName: AtrigamUniverseNodeName;
}
export interface getWorkItemSubscribersResult {
    subscribers: Subscriber[];
}
export interface Subscriber extends Pick<AtrigamUser, "uid" | "firstname" | "lastname" | "company" | "phoneNumber" | "jobtitle" | "profilePicture" | "salutation" | "emailIsBlocked" | "emailIsBounced" | "emailIsMarkedAsSpam"> {
    // user email can be undefined
    email?: AtrigamUser["email"];
    hasNoUser?: boolean;
    // subscription
    invitedRoles: AtrigamSubscriptionRoleInvite[];
    isPendingInvite: boolean;
    hasPendingInviteEntry: boolean;
    subscriptionState: AtrigamSubscriptionState;
    // clientRole
    clientRoles: AtrigamRole[];
}
export const getWorkItemSubscribersHttpOnCallFunction = createOnCallFunctionClientSDK<getWorkItemSubscribersParameters, getWorkItemSubscribersResult, unknown>({ appName: APP_NAME, functionName: 'universes-getWorkItemSubscribers', region: 'europe-west3' });

// universes-leaveUniverseAsModelerUser 
export interface leaveUniverseAsModelerUserParameters {
    universe: AtrigamUniverseName;
}
export type leaveUniverseAsModelerUserResult = AtrigamUser | undefined;
export const leaveUniverseAsModelerUserHttpOnCallFunction = createOnCallFunctionClientSDK<leaveUniverseAsModelerUserParameters, leaveUniverseAsModelerUserResult, unknown>({ appName: APP_NAME, functionName: 'universes-leaveUniverseAsModelerUser', region: 'europe-west3' });

// universes-getUniversesInfo 
export interface getUniversesInfoParameters {
    universes: AtrigamUniverseName[];
}
export interface getUniversesInfoResult {
    universeInfo?: (AtrigamUniverseRegistration | undefined)[];
}
export const getUniversesInfoHttpOnCallFunction = createOnCallFunctionClientSDK<getUniversesInfoParameters, getUniversesInfoResult, unknown>({ appName: APP_NAME, functionName: 'universes-getUniversesInfo', region: 'europe-west3' });

// universes-checkUniverseAvailability 
export interface checkUniverseAvailabilityParameters {
    universe: AtrigamUniverseName;
}
export interface checkUniverseAvailabilityResult {
    universe: AtrigamUniverseName;
    universeNodeName: AtrigamUniverseNodeName;
    status: "registered" | "available";
}
export const checkUniverseAvailabilityHttpOnCallFunction = createOnCallFunctionClientSDK<checkUniverseAvailabilityParameters, checkUniverseAvailabilityResult, unknown>({ appName: APP_NAME, functionName: 'universes-checkUniverseAvailability', region: 'europe-west3' });

// platform-updateAppVersion 
export interface updateAppVersionParameters {
    app: AtrigamPlatformApps;
    version: string;
    runNumber?: number;
    user?: string;
}
export type updateAppVersionResult = undefined;
export const updateAppVersionHttpFunction = createHttpFunctionClientSDK<updateAppVersionParameters, updateAppVersionResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'platform-updateAppVersion', region: 'europe-west3'  });

// contact-contactAtrigam 
export interface contactAtrigamParameters {
    name?: string;
    subject?: string;
    message?: string;
}
export type contactAtrigamResult = undefined;
export const contactAtrigamHttpFunction = createHttpFunctionClientSDK<contactAtrigamParameters, contactAtrigamResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'contact-contactAtrigam', region: 'europe-west3'  });

// batchOperations-archiveBatchOperation 
export interface archiveBatchOperationParameters {
    id: string;
}
export interface archiveBatchOperationResult {
    success: boolean;
}
export const archiveBatchOperationHttpOnCallFunction = createOnCallFunctionClientSDK<archiveBatchOperationParameters, archiveBatchOperationResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-archiveBatchOperation', region: 'europe-west3' });

// batchOperations-queueWipeData 
export interface queueWipeDataParameters extends Pick<AtrigamBatchOperationWipeData, "environment"> {
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
}
export type queueWipeDataResult = AtrigamBatchOperationsQueueItem<AtrigamBatchOperationWipeData> | undefined;
export const queueWipeDataHttpOnCallFunction = createOnCallFunctionClientSDK<queueWipeDataParameters, queueWipeDataResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-queueWipeData', region: 'europe-west3' });

// batchOperations-validateUploadFile 
export interface validateUploadFileParameters {
    filePath: string;
    environment: AtrigamEnvironment;
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
}
export type validateUploadFileResult = AtrigamBatchOperationUploadDataValidationReport;
export const validateUploadFileHttpOnCallFunction = createOnCallFunctionClientSDK<validateUploadFileParameters, validateUploadFileResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-validateUploadFile', region: 'europe-west3' });

// batchOperations-queueUploadData 
export interface queueUploadDataParameters extends Pick<AtrigamBatchOperationUploadData, "environment" | "filePath"> {
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
}
export type queueUploadDataResult = AtrigamBatchOperationsQueueItem<AtrigamBatchOperationUploadData> | undefined;
export const queueUploadDataHttpOnCallFunction = createOnCallFunctionClientSDK<queueUploadDataParameters, queueUploadDataResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-queueUploadData', region: 'europe-west3' });

// batchOperations-queuePrepareStaging 
export interface queuePrepareStagingParameters {
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
}
export type queuePrepareStagingResult = AtrigamBatchOperationsQueueItem<AtrigamBatchOperationPrepareStaging> | undefined;
export const queuePrepareStagingHttpOnCallFunction = createOnCallFunctionClientSDK<queuePrepareStagingParameters, queuePrepareStagingResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-queuePrepareStaging', region: 'europe-west3' });

// batchOperations-queueInviteUsers 
export interface queueInviteUsersParameters extends Omit<AtrigamBatchOperationInviteUsers, "batchOperationType"> {
    universe: AtrigamUniverseName;
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
}
export type queueInviteUsersResult = AtrigamBatchOperationsQueueItem<AtrigamBatchOperationInviteUsers> | undefined;
export const queueInviteUsersHttpOnCallFunction = createOnCallFunctionClientSDK<queueInviteUsersParameters, queueInviteUsersResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-queueInviteUsers', region: 'europe-west3' });

// batchOperations-getDataUploadTemplate 
/**
 *
 * get upload template system for a task flow
 *
 */
export interface getDataUploadTemplateParameters extends Pick<AtrigamBatchOperationUploadData, "environment"> {
    area: AtrigamAreaName;
    flow: AtrigamTaskFlowName;
    universe: AtrigamUniverseName;
}
export interface getDataUploadTemplateResult {
    templateUrl: string;
}
export const getDataUploadTemplateHttpOnCallFunction = createOnCallFunctionClientSDK<getDataUploadTemplateParameters, getDataUploadTemplateResult, unknown>({ appName: APP_NAME, functionName: 'batchOperations-getDataUploadTemplate', region: 'europe-west3' });

// backup-getBackupConfigurations 
export type getBackupConfigurationsParameters = unknown;
export interface getBackupConfigurationsResult {
    configurations: AtrigamUniverseBackupConfiguration[];
}
export const getBackupConfigurationsHttpOnCallFunction = createOnCallFunctionClientSDK<getBackupConfigurationsParameters, getBackupConfigurationsResult, unknown>({ appName: APP_NAME, functionName: 'backup-getBackupConfigurations', region: 'europe-west3' });

// api-dataLink 
// API cannot be handled via clientSDK, sorry
export type dataLinkParameters = unknown;
export type dataLinkResult = unknown;
export const dataLinkHttpFunction = createHttpFunctionClientSDK<dataLinkParameters, dataLinkResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'api-dataLink', region: 'us-central1'  });

// algolia-indexSingleUser 
export interface indexSingleUserParameters {
    uid?: UID;
}
export type indexSingleUserResult = undefined;
export const indexSingleUserHttpFunction = createHttpFunctionClientSDK<indexSingleUserParameters, indexSingleUserResult>({ appUrlTemplate: APP_URL_TEMPLATE, functionName: 'algolia-indexSingleUser', region: 'europe-west3'  });

